import { useRouter } from 'next/router'
import { type FC, memo, useMemo } from 'react'
import styled from 'styled-components'

import UnderlineTabs from 'components/Button/UnderlineTabs'
import ROUTES from 'constants/routes'
import media from 'styles/media'

export const MarketTabs = {
	ALL_MARKETS: 'all_markets',
	FAVORITES: 'favorites',
	NEWLY_LISTED: 'newly_listed',
} as const

export type MarketTab = (typeof MarketTabs)[keyof typeof MarketTabs]

export const MarketTabValues = Object.values(MarketTabs)

type MarketTabsProps = {
	activeTab: MarketTab
}

const MarketTabsMenu: FC<MarketTabsProps> = memo(({ activeTab }) => {
	const router = useRouter()
	const marketTabs = useMemo(
		() => [
			{
				name: MarketTabs.ALL_MARKETS,
				label: 'All Markets',
				active: activeTab === MarketTabs.ALL_MARKETS,
				onClick: () => router.push(ROUTES.Dashboard.Markets(MarketTabs.ALL_MARKETS)),
			},
			{
				name: MarketTabs.FAVORITES,
				label: 'Favorites',
				active: activeTab === MarketTabs.FAVORITES,
				onClick: () => router.push(ROUTES.Dashboard.Markets(MarketTabs.FAVORITES)),
			},
			{
				name: MarketTabs.NEWLY_LISTED,
				label: 'Newly Listed',
				active: activeTab === MarketTabs.NEWLY_LISTED,
				onClick: () => router.push(ROUTES.Dashboard.Markets(MarketTabs.NEWLY_LISTED)),
			},
		],
		[activeTab, router]
	)
	return <StyledUnderlineTabs options={marketTabs} noBorders />
})

const StyledUnderlineTabs = styled(UnderlineTabs)`
	height: 45px;
	& > div:first-child {
		padding-left: 0;
	}

	${media.lessThan('lg')`
		overflow-x: auto;
		white-space: nowrap;
	`}
`

export default MarketTabsMenu
