import type { FC } from 'react'
import styled from 'styled-components'

import { Body } from 'components/Text'

type Props = {
	count: number
}

const CountBadge: FC<Props> = ({ count }) => {
	return (
		<CountContainer mono weight="bold">
			{count}
		</CountContainer>
	)
}

const CountContainer = styled(Body)`
	height: 16px;
	width: fit-content;
	min-width: 16px;
	padding-left: 4px;
	padding-right: 4px;
	margin-left: 7px;
	font-size: 13px;
	color: ${(props) => props.theme.colors.selectedTheme.black};
	background-color: ${(props) => props.theme.colors.selectedTheme.newTheme.badge.yellow.background};
	border-radius: 4px;
`

export default CountBadge
