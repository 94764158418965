import type { NetworkId } from '@kwenta/sdk/types'
import { http, createPublicClient } from 'viem'
import { mainnet } from 'viem/chains'

import { SUPPORTED_CHAIN_IDS } from 'constants/network'

export const staticMainnetClient = createPublicClient({
	chain: mainnet,
	transport: http(
		`https://eth-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`
	),
})

export function isSupportedNetworkId(id: NetworkId): boolean {
	return SUPPORTED_CHAIN_IDS.includes(id)
}
