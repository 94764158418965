import { useCallback, useEffect, useState } from 'react'
import { type Address, type GetBlockReturnType, formatEther, isAddress } from 'viem'

import { selectAccountContext } from 'state/futures/selectors'
import { useAppSelector } from 'state/hooks'
import sdk from 'state/sdk'

export default function useBalance(account: Address | undefined, watch = true, blockInterval = 15) {
	const [balance, setBalance] = useState<string>()
	const [error, setError] = useState<unknown>()

	const { network: chainId } = useAppSelector(selectAccountContext)

	const fetchBalance = useCallback(
		async (block?: GetBlockReturnType<any>) => {
			try {
				// Check ETH balance every blockInterval blocks (reduce RPC calls)
				if (!account || (block?.number && block.number % BigInt(blockInterval) !== BigInt(0))) {
					return
				}

				const client = sdk.context.clients[chainId] ?? sdk.context.signerPublicClient
				const balance = await client.getBalance({ address: account })
				const formattedBalance = formatEther(balance)
				setBalance(formattedBalance)
			} catch (err) {
				setError(err)
			}
		},
		[account, blockInterval, chainId]
	)

	useEffect(() => {
		if (account && isAddress(account)) {
			fetchBalance()
			if (watch) {
				const unwatch = sdk.context.clients[chainId]?.watchBlocks({ onBlock: fetchBalance })
				return unwatch
			}
		}
	}, [account, fetchBalance, watch, chainId])

	return { balance, error }
}
