import type { FC } from 'react'
import styled, { css } from 'styled-components'

import GitbookIcon from 'assets/svg/gitbook.svg'
import DiscordIcon from 'assets/svg/social/discord-2.svg'
import MirrorIcon from 'assets/svg/social/mirror-2.svg'
import TwitterIcon from 'assets/svg/social/twitter-2.svg'
import { EXTERNAL_LINKS } from 'constants/links'

type LinksProps = {
	isMobile?: boolean
}

const Links: FC<LinksProps> = ({ isMobile }) => (
	<LinkContainer $isMobile={isMobile}>
		<a href={EXTERNAL_LINKS.Social.Twitter} target="_blank" rel="noreferrer">
			<TwitterIcon height={15} />
		</a>
		<a href={EXTERNAL_LINKS.Social.Discord} target="_blank" rel="noreferrer">
			<DiscordIcon height={15} />
		</a>
		<a href={EXTERNAL_LINKS.Docs.DocsRoot} target="_blank" rel="noreferrer">
			<GitbookIcon height={15} />
		</a>
		<a href={EXTERNAL_LINKS.Social.Mirror} target="_blank" rel="noreferrer">
			<MirrorIcon height={15} />
		</a>
	</LinkContainer>
)

const LinkContainer = styled.div<{ $isMobile?: boolean }>`
	display: flex;
	gap: 22px;
	align-items: center;
	justify-content: center;

	${(props) =>
		props.$isMobile &&
		css`
			margin-top: 0;
			margin-left: 0;

			a {
				outline: none;
			}
		`}
`

export default Links
