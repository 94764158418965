import type { NetworkId } from '@kwenta/sdk/types'
import { useConnectModal } from '@rainbow-me/rainbowkit'

import { type FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ConnectButton from 'components/Button'
import { PROVIDER_CHAIN_NAMES, PROVIDER_MAINNET_CHAINS } from 'constants/network'
import Connector from 'containers/Connector'
import useNetworkSwitcher from 'hooks/useNetworkSwitcher'
import { WatcherModeIcon } from 'sections/futures/WatcherMode/WatchOrConnectWallet'
import { setOpenModal } from 'state/app/reducer'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { isSupportedNetworkId } from 'utils/network'

import ConnectionDot from '../ConnectionDot'

import MobileWalletActions from './MobileWalletActions'

type MobileConnectButtonProps = {
	toggleModal(): void
}

const MobileConnectOrWatchButtons: FC<MobileConnectButtonProps> = ({ toggleModal }) => {
	const { t } = useTranslation()
	const { openConnectModal } = useConnectModal()
	const dispatch = useAppDispatch()

	const handleConnect = useCallback(() => {
		toggleModal()
		openConnectModal?.()
	}, [toggleModal, openConnectModal])

	const handleWatchOrConnect = useCallback(() => {
		toggleModal()
		dispatch(setOpenModal('watch_or_connect_wallet'))
	}, [toggleModal, dispatch])

	return (
		<Container>
			<ConnectButton
				size="small"
				variant="flat"
				noOutline
				onClick={handleConnect}
				data-testid="connect-wallet"
				mono
			>
				<ConnectionDot />
				{t('common.wallet.connect')}
			</ConnectButton>

			<ConnectButton size="small" variant="flat" noOutline onClick={handleWatchOrConnect} mono>
				<WatcherModeIcon />
				{t('common.wallet.watch')}
			</ConnectButton>
		</Container>
	)
}

const MobileUnsupportedButton = () => {
	const { t } = useTranslation()
	const perpsProvider = useAppSelector(selectPerpsProvider)

	const switchButtonLabel = t('homepage.l2.cta-buttons.switch-l2', {
		chain: PROVIDER_CHAIN_NAMES[perpsProvider],
	})

	const { switchToL2 } = useNetworkSwitcher(PROVIDER_MAINNET_CHAINS[perpsProvider].id)

	return (
		<Container>
			<ConnectButton size="small" mono variant="flat" onClick={switchToL2}>
				{switchButtonLabel}
			</ConnectButton>
			<ConnectButton size="small" variant="flat" data-testid="unsupported-network" mono>
				<ConnectionDot />
				{t('common.wallet.unsupported-network')}
			</ConnectButton>
		</Container>
	)
}

type MobileWalletButtonProps = {
	toggleModal(): void
}

const MobileWalletButton: FC<MobileWalletButtonProps> = ({ toggleModal }) => {
	const { isWalletConnected, activeChain } = Connector.useContainer()

	if (!isWalletConnected) {
		return <MobileConnectOrWatchButtons toggleModal={toggleModal} />
	} else if (isSupportedNetworkId(activeChain?.id as NetworkId)) {
		return <MobileWalletActions />
	} else {
		return <MobileUnsupportedButton />
	}
}

const Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
`

export default MobileWalletButton
