import Link from 'next/link'
import { useRouter } from 'next/router'
import { type FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CaretDownIcon from 'assets/svg/app/caret-down.svg'
import FullScreenModal from 'components/FullScreenModal'
import { FlexDivCol, FlexDivRow, FlexDivRowCentered } from 'components/layout/flex'
import ROUTES from 'constants/routes'
import Links from 'sections/dashboard/Links'
import Logo from 'sections/shared/Layout/Logo'

import { HOMEPAGE_MENU_LINKS, MOBILE_NAV_LINKS, NavDropdowns } from '../constants'

import MobileIntegrationsDropDown from './MobileIntegrationsDropDown'
import MobileSubMenu from './MobileSubMenu'
import { MenuButton } from './menu'

type MobileMenuModalProps = {
	onDismiss(): void
}

export const MobileMenuModal: FC<MobileMenuModalProps> = ({ onDismiss }) => {
	const { t } = useTranslation()
	const router = useRouter()

	const isHome = useMemo(() => {
		return window.location.pathname === ROUTES.Home.Root
	}, [])

	const menuLinks = isHome ? HOMEPAGE_MENU_LINKS : MOBILE_NAV_LINKS

	const showStatsPage = useCallback(() => {
		router.push(ROUTES.Stats.Home)
		onDismiss()
	}, [router, onDismiss])

	const activeLink = useMemo(() => {
		return menuLinks.find((item) => item.link.includes(router.asPath.split('/')[1]))?.link
	}, [menuLinks, router.asPath])

	const [focusedLink, setFocusedLink] = useState(
		menuLinks.find((item) => item.link.includes(router.asPath.split('/')[1]))?.link
	)

	const handleOnMenuLinkClick = useCallback(
		(link: string) => {
			if (link === focusedLink) {
				setFocusedLink(activeLink)
			} else {
				setFocusedLink(link)
			}
		},
		[focusedLink, activeLink]
	)

	return (
		<StyledFullScreenModal isOpen>
			<Container>
				<LogoContainer>
					<Logo />
					<MetaRow>
						<Links isMobile />
					</MetaRow>
				</LogoContainer>
				<MenuContainer>
					{menuLinks.map(({ i18nLabel, link, links, dropdown }) => {
						const isActive = link === activeLink && !isHome
						const isFocused = focusedLink === link

						return (
							<MenuItemContainer key={link}>
								{links?.length ? (
									<MenuButton
										isActive={isActive}
										isFocused={isFocused}
										onClick={() => handleOnMenuLinkClick(link)}
									>
										<MenuButtonTitle isFocused={isFocused} className="menu-title">
											<FlexDivRowCentered>
												<span>{t(i18nLabel)}</span>
											</FlexDivRowCentered>
											<CaretDownIcon className="caret" />
										</MenuButtonTitle>
										<MenuButtonDropdown isOpen={isFocused} size="100px">
											<MobileSubMenu
												links={links}
												i18nLabel={i18nLabel}
												defaultOpen={isActive}
												onDismiss={onDismiss}
											/>
										</MenuButtonDropdown>
									</MenuButton>
								) : link === ROUTES.Stats.Home ? (
									<MenuButton isActive={isActive} onClick={showStatsPage}>
										<MenuButtonTitle className="menu-title">
											<FlexDivRowCentered>
												<span>{t(i18nLabel)}</span>
											</FlexDivRowCentered>
										</MenuButtonTitle>
									</MenuButton>
								) : dropdown?.type === NavDropdowns.Futures ? (
									<MenuButton
										isActive={isActive}
										isFocused={isFocused}
										onClick={() => handleOnMenuLinkClick(link)}
									>
										<MenuButtonTitle isFocused={isFocused} className="menu-title">
											<FlexDivRowCentered>
												<span>{t(i18nLabel)}</span>
											</FlexDivRowCentered>
											<CaretDownIcon className="caret" />
										</MenuButtonTitle>
										<MenuButtonDropdown isOpen={isFocused} size="250px">
											<MobileIntegrationsDropDown networks={dropdown.items} onDismiss={onDismiss} />
										</MenuButtonDropdown>
									</MenuButton>
								) : (
									<MenuButton isActive={isActive} onClick={onDismiss} isLink>
										<MenuButtonTitle className="menu-title">
											<Link href={link}>
												<FlexDivRowCentered>
													<span>{t(i18nLabel)}</span>
												</FlexDivRowCentered>
											</Link>
										</MenuButtonTitle>
									</MenuButton>
								)}
							</MenuItemContainer>
						)
					})}
				</MenuContainer>
			</Container>
		</StyledFullScreenModal>
	)
}

const MenuButtonTitle = styled(FlexDivRow)<{ isFocused?: boolean }>`
	justify-content: space-between;
	width: 100%;
	${(props) =>
		props.isFocused && `background-color: ${props.theme.colors.common.palette.neutral.n800}`};
	height: 50px;
	align-items: center;
	padding: 0 20px;
`

const MenuButtonDropdown = styled(FlexDivCol)<{
	isOpen: boolean
	size: string
}>`
	width: 100%;
	height: ${(props) => (props.isOpen ? props.size : 0)};
	opacity: ${(props) => (props.isOpen ? 1 : 0)};
	${(props) => `transition: all 0.3s ease-in-out ${props.isOpen ? '0.2s' : '0.2s'}`};
	padding: ${(props) => (props.isOpen ? '20px' : 0)};
`
const StyledFullScreenModal = styled(FullScreenModal)`
	top: 0;
	background-color: ${(props) => props.theme.colors.common.dark.darkBackground};

	[data-reach-dialog-content] {
		margin: 0;
		width: 100%;
		height: 100%;

		& > div {
			height: 100%;
		}
	}
`

const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: scroll;
`

const MenuContainer = styled(FlexDivCol)`
	padding-bottom: 120px;
`

const MenuItemContainer = styled(FlexDivRow)`
	position: relative;
	align-items: center;
`

const LogoContainer = styled(FlexDivRow)`
	padding: 20px 30px;
`

const MetaRow = styled(FlexDivRow)`
	width: 100%;
	justify-content: flex-end;
`

export default MobileMenuModal
